import React, { useRef } from "react";
import styled from "styled-components";
import { Colors, Devices } from "../../styles/variables";
import { EzDivisor, RespContainer } from "../../styles/common";
import { useWindowSize } from "./../CustomHooks/useWindowSize";

import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, { A11y, Pagination } from "swiper";
import { TestimonialsType } from "../../shared/interfaces/graphql.interface";

// install Swiper modules
SwiperCore.use([A11y, Pagination]);

interface TestimonialsProps {
  testimonials: TestimonialsType;
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  const { width }: { width: Number } = useWindowSize();

  const isMobile = () => +width < 768;

  return (
    <TestimonialsWrapper>
      <TestimonialsTitle>Testimonials</TestimonialsTitle>
      <TestimonialsDivisor alwaysBlue />
      <Swiper
        // slidesPerView={1}
        spaceBetween={isMobile() ? 16 : 32}

        breakpoints={{
          0: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
          
          },
          768: {
            slidesPerView: 1.4,
            slidesPerGroup: 1,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          }
        }}
        pagination={{clickable: true}}
        grabCursor
      >
        {testimonials.map((test, idx) => (
          <SwiperSlide key={`test${idx}`}>
            <TestimonialWrapper>
              <TestimonialDecor
                src="/images/testimonial-decor.svg"
                alt={test?.acf?.name}
              />
              <TestimonialDesc
                dangerouslySetInnerHTML={{
                  __html: test?.content,
                }}
              />
              <TestimonialAuthor>{test?.acf?.name}</TestimonialAuthor>
              <TestimonialLink
                href={test?.acf?.website?.link}
                target="_blank"
                rel="noopener,noreferrer"
              >
                <TestimonialGlobe
                  src="/images/icon-globe.svg"
                  alt={test?.acf?.website?.title}
                />
                {test?.acf?.website?.title}
              </TestimonialLink>
            </TestimonialWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </TestimonialsWrapper>
  );
};

export default Testimonials;

const TestimonialsWrapper = styled(RespContainer)`
  padding: 2rem 1rem;
  overflow-x: hidden;

  //as we want to see details which are being cut of by default css from swiper contner we change it top overflow visible.
  .swiper-container {
    overflow: visible;
  }
  //we changed over hidden to visible all slides of the swiper slider would we visible so we need to hide them so we add below css.
  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    height: auto;
    align-items: flex-start;
  }
  // now because of the above css all the slider are set to opacity 0 , which makes them not visible, now as we want to see out active and next slide we supply below css by using their name. and set there opacity to 1.
  .swiper-slide.swiper-slide-active,
  .swiper-slide.swiper-slide-next,
  .swiper-slide.swiper-slide-prev {
    opacity: 1;
    visibility: visible;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    padding: 1rem 0 81px;

    .swiper-slide.swiper-slide-next + .swiper-slide {
      opacity: 1;
      visibility: visible;
    }

    .swiper-slide.swiper-slide-prev {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

const TestimonialsTitle = styled(`h2`)`
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  color: ${Colors.dodgeBlue2};
  text-align: center;
  margin-top: 38px;
  margin-bottom: 14px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    font-size: 60px;
    margin-top: 67px;
    margin-bottom: 28px;
  }
`;

const TestimonialsDivisor = styled(EzDivisor)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 64px;

  &::after {
    left: auto;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    margin-bottom: 112px;
  }
`;

const TestimonialWrapper = styled(`div`)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 40px 24px 24px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    padding: 70px 37px 42px;
  }
`;

const TestimonialDecor = styled(`img`)`
  position: absolute;
  left: auto;
  right: auto;
  top: -21px;
  width: 42px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    top: -34px;
    width: 68px;
  }
`;

const TestimonialDesc = styled(`div`)`
  flex-grow: 1;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0;

  &::before {
    content: "“";
    color: ${Colors.sharpBlue};
  }

  &::after {
    content: "“";
    color: ${Colors.sharpBlue};
  }

  & > * {
    display: inline;
  }
`;

const TestimonialAuthor = styled(`b`)`
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin: 24px 0 16px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    font-size: 24px;
    margin: 40px 0 16px;
  }
`;

const TestimonialLink = styled(`a`)`
  display: flex;
  align-items: center;
  color: ${Colors.sharpBlue};
  font-size: 18px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
  }
`;

const TestimonialGlobe = styled(`img`)`
  margin-right: 10.5px;
`;
